//@flow
import * as React from 'react'
import styled from '@emotion/styled'
import Theme from '../../utils/theme'
const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  cursor: pointer;
`

/**
 * TODO: Separate this to be unsed in EventRefinement
 */
const OptionContainer = styled(Container)`
  background: ${Theme.colors.tertiaryFill};
`

const Checkbox = styled.div`
  min-width: 24px;
  min-height: 24px;
  background-color: ${p =>
    p.checked ? Theme.colors.selection : Theme.colors.invertedFont};
  margin-right: 10px;
  border-radius: 3px;
  border: 1px solid ${Theme.colors.primaryFill};
`

const Label = styled.div`
  color: ${Theme.colors.primaryFont};
  /* font-size: 0.9rem; */
  ltext-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: ${Theme.fonts.body};
`

type Props = {
  defaultSelected: boolean,
  label?: React.Node,
  onClick?: (value: string | string[]) => any,
  value: string[] | string,
}

type State = {
  selected: boolean,
}

class SelectOption extends React.PureComponent<Props, State> {
  state = {
    selected: this.props.defaultSelected,
  }
  static getDerivedStateFromProps(nextProps: Props, state: State) {
    if (nextProps.defaultSelected !== state.selected) {
      return {
        selected: nextProps.defaultSelected,
      }
    }
    return null
  }
  handleClick = () => {
    this.setState({
      selected: !this.state.selected,
    })
    if (this.props.onClick) {
      this.props.onClick(this.props.value)
    }
  }
  render() {
    const { label } = this.props
    const { selected } = this.state

    return (
      <OptionContainer onClick={this.handleClick}>
        <Checkbox checked={selected} />
        <Label>{label}</Label>
      </OptionContainer>
    )
  }
}

export const Option = ({
  checked,
  label,
  onClick,
  name,
}: {
  checked: boolean,
  label: string,
  onClick?: Function,
  name: string,
}) => {
  return (
    <Container onClick={onClick}>
      <input type="hidden" name={name} checked={checked} />
      <Checkbox checked={checked} />
      <Label>{label}</Label>
    </Container>
  )
}

export default SelectOption
