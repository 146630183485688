//@flow
export default {
  arts: 'Arts',
  business: 'Business',
  charityAndVolunteer: 'Charity & Volunteer',
  childrenAndFamily: 'Children & Family',
  concert: 'Concert',
  fairAndFestival: 'Fair & Festival',
  foodAndDrink: 'Food & Drink',
  healthAndWellness: 'Health & Wellness',
  holiday: 'Holiday',
  nightlife: 'Nightlife',
  outdoorAndRecreation: 'Outdoor & Recreation',
  religious: 'Religious',
  scienceAndTech: 'ScienceAndTech',
  sports: 'Sports',
  theatreAndPerformingArts: 'Theatre & Performing Arts',
}
