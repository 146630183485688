//@flow
import * as React from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import { Formik, Field } from 'formik'
import { Flex, Box } from 'grid-styled/emotion'
import * as yup from 'yup'
import moment from 'moment'
import { rhythm } from '../../utils/typography'

/**
 * Components
 */
import Layout from '../../components/Layout'
import Page from '../../components/ui/Page'
import SplitContainer from '../../components/ui/SplitContainer'
import Modal from '../../components/ui/Modal'
import Input from '../../components/ui/Input'
import Button from '../../components/ui/Button'
import { Option } from '../../components/ui/SelectOption'
import { AgeGroupsCamel } from '../../constants/ageGroups'
import categories from '../../constants/categories'

/**
 * Images
 */
import modalsuccessimage from './images/adam-whitlock-unsplash.jpg'
import modalfailimage from './images/neonbrand-unsplash.jpg'
import sideImage from './images/sideImage.jpg'

const { Title, Paragraph: P } = SplitContainer

const FormSection = styled.div`
  margin-bottom: ${rhythm(3)};
`

function flattenObject(obj, inRet, inPrefix) {
  const ret = inRet || {}
  const prefix = inPrefix || ''
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).forEach(key => {
      flattenObject(obj[key], ret, prefix === '' ? key : `${prefix}[${key}]`)
    })
  } else if (prefix !== '') {
    ret[prefix] = obj
  }

  return ret
}

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1)

const prefixFieldName = (prefix, fieldName) =>
  `${prefix}${capitalizeFirstLetter(fieldName)}`

const schema = yup.object().shape({
  eventName: yup.string().required('Name is required'),
  eventDate: yup
    .date()
    .min(moment().toDate(), 'Date must be after today')
    .required('Date is required')
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv
    }),
  eventStartTime: yup.string().required('Start time is required'),
  eventEndTime: yup.string().required('End time is required'),
  eventWebsite: yup.string().required(),
})

const FORM_NAME = 'add-event-form'

// TODO: Complete with values
const getInitialFormValues = () => {
  let categories = {}
  let ageGroups = {}
  Object.keys(categories).map(k => {
    categories[prefixFieldName('categories', k)] = ''
  })
  Object.keys(AgeGroupsCamel).map(k => {
    ageGroups[prefixFieldName('ageGroups', k)] = ''
  })

  let form = {
    eventName: '',
    eventWebsite: '',
    eventDate: '',
    eventStartTime: '',
    eventEndTime: '',
    eventSummary: '',
    eventDescription: '',
    ticketWebsite: '',
    ticketPriceMin: '',
    ticketPriceMax: '',
    ticketDescription: '',
    venueName: '',
    venueAddress: '',
    venueCity: '',
    venueZipCode: '',
    venuePhoneNumber: '',
    venueWebsite: '',
    ...categories,
    ...ageGroups,
    contactName: '',
    contactRole: '',
    contactPhone: '',
    contactEmail: '',
  }
  return form
}

type State = {
  showSuccess: boolean,
  showError: boolean,
}

class AddEventPage extends React.PureComponent<*, State> {
  state = {
    showSuccess: false,
    showError: false,
  }
  handleSubmit = (values: *, { resetForm }: *) => {
    try {
      const flatten = flattenObject({
        'form-name': FORM_NAME,
        ...values,
      })

      return fetch('/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: encode(flatten),
      })
        .then(() => {
          this.setState({ showSuccess: true })
          resetForm(getInitialFormValues())
        })
        .catch(() => this.setState({ showError: true }))
    } catch (err) {
      console.error(err)
    }
  }
  render() {
    const { showSuccess, showError } = this.state
    return (
      <Layout>
        <Page>
          <Helmet title={`Add an event to Park City Insider`} />
          <SplitContainer imageUrl={sideImage}>
            <FormSection>
              <Title>Add an event </Title>
              <P>
                After submitting your event details, we will review them and
                publish them to Park City Insider. The more information you
                provide, the easier it is for Insiders to go to your event. Keep
                in mind how you type in the information is how it will appear on
                the site.
              </P>
              <P>
                We recommend all event listings to be submitted at least two
                weeks in advance of start date. Once reviewed, we will post your
                event within 48 hours. If we need further clarification, then we
                will reach out to you with the contact information you provide.
              </P>
            </FormSection>
            <Formik
              initialValues={getInitialFormValues()}
              validationSchema={schema}
              validateOnBlur={false}
              onSubmit={this.handleSubmit}
              render={({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                //$FlowFixMe - flow-typed issue maybe?
                setFieldValue,
              }) => {
                return (
                  <form
                    onSubmit={handleSubmit}
                    name={FORM_NAME}
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    <FormSection>
                      <input
                        type="hidden"
                        name="form-name"
                        value="add-event-form"
                      />
                      <h3>Event Details</h3>
                      <Input.FormikWrapper
                        label="Event Name"
                        hasError={t => t.eventName}
                        form={{ errors, touched }}
                      >
                        <Field name={'eventName'} component={Input.Formik} />
                      </Input.FormikWrapper>
                      <Input.FormikWrapper
                        label="Event Website"
                        hasError={t => t.eventWebsite}
                        form={{ errors, touched }}
                      >
                        <Field name={'eventWebsite'} component={Input.Formik} />
                      </Input.FormikWrapper>
                      <Input.FormikWrapper
                        label="Event Date"
                        hasError={t => t.eventDate}
                        form={{ errors, touched }}
                      >
                        <Field
                          name={'eventDate'}
                          component={Input.FormikDate}
                        />
                      </Input.FormikWrapper>
                      <Flex flexDirection={['column', 'row']}>
                        <Box width={[1, 1 / 2]} mr={[0, 3]}>
                          <Input.FormikWrapper
                            label="Start Time"
                            hasError={t => t.eventStartTime}
                            form={{
                              errors,
                              touched,
                            }}
                          >
                            <Field
                              name={'eventStartTime'}
                              component={Input.Formik}
                            />
                          </Input.FormikWrapper>
                        </Box>
                        <Box width={[1, 1 / 2]}>
                          <Input.FormikWrapper
                            label="End Time"
                            hasError={t => t.eventEndTime}
                            form={{
                              errors,
                              touched,
                            }}
                          >
                            <Field
                              name={'eventEndTime'}
                              component={Input.Formik}
                            />
                          </Input.FormikWrapper>
                        </Box>
                      </Flex>

                      <Input.FormikWrapper
                        label="Event summary preview"
                        hasError={t => t.eventSummary}
                        form={{ errors, touched }}
                      >
                        <Field
                          name={'eventSummary'}
                          component={Input.FormikTextArea}
                        />
                      </Input.FormikWrapper>
                      <Input.FormikWrapper
                        label="Event description"
                        hasError={t => t.eventDescription}
                        form={{ errors, touched }}
                      >
                        <Field
                          name={'eventDescription'}
                          component={Input.FormikTextArea}
                        />
                      </Input.FormikWrapper>
                    </FormSection>
                    <FormSection>
                      <h3>Ticket Information</h3>
                      <Input.FormikWrapper
                        label="Ticket Purchase Website"
                        hasError={t => t.ticketWebsite}
                        form={{ errors, touched }}
                      >
                        <Field
                          name={'ticketWebsite'}
                          component={Input.Formik}
                        />
                      </Input.FormikWrapper>
                      <Flex flexDirection={['column', 'row']}>
                        <Box width={[1, 1 / 2]} mr={[0, 3]}>
                          <Input.FormikWrapper
                            label="Minimum Price"
                            hasError={t => t.ticketPriceMin}
                            form={{
                              errors,
                              touched,
                            }}
                            renderIcon={<Input.Icon>$</Input.Icon>}
                            renderField={
                              <Input
                                type="text"
                                name="ticketPriceMin"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ticketPriceMin}
                                placeholder={'00.00'}
                              />
                            }
                          />
                        </Box>
                        <Box width={[1, 1 / 2]}>
                          <Input.FormikWrapper
                            label="Maximum Price"
                            hasError={t => t.ticketPriceMax}
                            form={{
                              errors,
                              touched,
                            }}
                            renderIcon={<Input.Icon>$</Input.Icon>}
                            renderField={
                              <Input
                                type="text"
                                name="ticketPriceMax"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ticketPriceMax}
                                placeholder={'00.00'}
                              />
                            }
                          />
                        </Box>
                      </Flex>
                      <Input.FormikWrapper
                        label="Description"
                        hasError={t => t.ticketDescription}
                        form={{ errors, touched }}
                      >
                        <Field
                          name={'ticketDescription'}
                          component={Input.FormikTextArea}
                        />
                      </Input.FormikWrapper>
                    </FormSection>
                    <FormSection>
                      <h3>Venue Details</h3>
                      <Input.FormikWrapper
                        label="Venue Name"
                        hasError={t => t.venueName}
                        form={{ errors, touched }}
                      >
                        <Field name={'venueName'} component={Input.Formik} />
                      </Input.FormikWrapper>
                      <Input.FormikWrapper
                        label="Venue Address"
                        hasError={t => t.venueAddress}
                        form={{ errors, touched }}
                      >
                        <Field name={'venueAddress'} component={Input.Formik} />
                      </Input.FormikWrapper>
                      <Flex flexDirection={['column', 'row']}>
                        <Box width={[1, 1 / 2]} mr={[0, 3]}>
                          <Input.FormikWrapper
                            label="Venue City"
                            hasError={t => t.venueCity}
                            form={{
                              errors,
                              touched,
                            }}
                          >
                            <Field
                              name={'venueCity'}
                              component={Input.Formik}
                            />
                          </Input.FormikWrapper>
                        </Box>
                        <Box width={[1, 1 / 2]}>
                          <Input.FormikWrapper
                            label="Venue Zip Code"
                            hasError={t => t.venueZipCode}
                            form={{
                              errors,
                              touched,
                            }}
                          >
                            <Field
                              name={'venueZipCode'}
                              component={Input.Formik}
                            />
                          </Input.FormikWrapper>
                        </Box>
                      </Flex>
                      <Input.FormikWrapper
                        label="Venue Phone Number"
                        hasError={t => t.venuePhoneNumber}
                        form={{ errors, touched }}
                      >
                        <Field
                          name={'venuePhoneNumber'}
                          component={Input.Formik}
                        />
                      </Input.FormikWrapper>
                      <Input.FormikWrapper
                        label="Venue Website"
                        hasError={t => t.venueWebsite}
                        form={{ errors, touched }}
                      >
                        <Field name={'venueWebsite'} component={Input.Formik} />
                      </Input.FormikWrapper>
                    </FormSection>
                    <FormSection>
                      <h3>Categories</h3>
                      <Input.FormikWrapper>
                        <Flex flexDirection={['column', 'row']}>
                          <Box>
                            {Object.keys(categories).map((k, i) => {
                              if (i >= 8) return

                              return (
                                <Option
                                  key={k}
                                  label={categories[k]}
                                  name={prefixFieldName('categories', k)}
                                  checked={
                                    values[prefixFieldName('categories', k)]
                                  }
                                  onClick={() => {
                                    const value = !values[
                                      prefixFieldName('categories', k)
                                    ]
                                    setFieldValue(
                                      prefixFieldName('categories', k),
                                      value
                                    )
                                  }}
                                />
                              )
                            })}
                          </Box>
                          <Box>
                            {Object.keys(categories).map((k, i) => {
                              if (i < 8) return
                              return (
                                <Option
                                  key={k}
                                  label={categories[k]}
                                  name={prefixFieldName('categories', k)}
                                  checked={
                                    values[prefixFieldName('categories', k)]
                                  }
                                  onClick={() => {
                                    const value = !values[
                                      prefixFieldName('categories', k)
                                    ]
                                    setFieldValue(
                                      prefixFieldName('categories', k),
                                      value
                                    )
                                  }}
                                />
                              )
                            })}
                          </Box>
                        </Flex>
                      </Input.FormikWrapper>
                    </FormSection>
                    <FormSection>
                      <h3>Age Groups</h3>
                      <Input.FormikWrapper>
                        <Flex>
                          <Box>
                            {Object.keys(AgeGroupsCamel).map(k => (
                              <Option
                                key={k}
                                label={AgeGroupsCamel[k]}
                                name={prefixFieldName('ageGroups', k)}
                                checked={
                                  values[prefixFieldName('ageGroups', k)]
                                }
                                onClick={() => {
                                  const value = !values[
                                    prefixFieldName('ageGroups', k)
                                  ]
                                  setFieldValue(
                                    prefixFieldName('ageGroups', k),
                                    value
                                  )
                                }}
                              />
                            ))}
                          </Box>
                        </Flex>
                      </Input.FormikWrapper>
                    </FormSection>
                    <FormSection>
                      <h3>Your Contact Information</h3>
                      <P>
                        Please provide us the correct information for the event
                        planner. We need these details in the case that we need
                        to contact you about your event.{' '}
                      </P>
                      <Input.FormikWrapper
                        label="First and last name"
                        hasError={t => t.contactName}
                        form={{ errors, touched }}
                        renderField={
                          <Input
                            type="text"
                            name="contactName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.contactName}
                          />
                        }
                      />
                      <Input.FormikWrapper
                        label="Role"
                        hasError={t => t.contactRole}
                        form={{ errors, touched }}
                        renderField={
                          <Input
                            type="text"
                            name="contactRole"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.contactRole}
                          />
                        }
                      />
                      <Flex flexDirection={['column', 'row']}>
                        <Box width={[1, 1 / 2]} mr={[0, 3]}>
                          <Input.FormikWrapper
                            label="Phone Number"
                            hasError={t => t.contactPhone}
                            form={{
                              errors,
                              touched,
                            }}
                            renderField={
                              <Input
                                type="text"
                                name="contactPhone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.contactPhone}
                              />
                            }
                          />
                        </Box>
                        <Box width={[1, 1 / 2]}>
                          <Input.FormikWrapper
                            label="Email"
                            hasError={t => t.contactEmail}
                            form={{
                              errors,
                              touched,
                            }}
                            renderField={
                              <Input
                                type="text"
                                name="contactEmail"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.contactEmail}
                              />
                            }
                          />
                        </Box>
                      </Flex>

                      <Button.Submit>Submit</Button.Submit>
                    </FormSection>
                  </form>
                )
              }}
            />
          </SplitContainer>

          <Modal
            show={showSuccess}
            title={'Success!'}
            description={
              'We have received your submissision. Expect to hear from us over the  next few days. '
            }
            renderButton={
              <Button
                type="button"
                onClick={() => {
                  this.setState({ showSuccess: false })
                }}
              >
                Close
              </Button>
            }
            imageUrl={modalsuccessimage}
          />
          <Modal
            show={showError}
            title={'Oops!'}
            description={
              'Something went wrong with the submission. Please wait a few minutes and then try again.'
            }
            renderButton={
              <Button
                type="button"
                onClick={() => {
                  this.setState({ showError: false })
                }}
              >
                Close
              </Button>
            }
            imageUrl={modalfailimage}
          />
        </Page>
      </Layout>
    )
  }
}

export default AddEventPage
